const isBrowser = typeof window !== "undefined" && window;

// Check all redirect paths in lowercase
const REDIRECT_MAP = {
  "/meet/effie": "https://calendly.com/afterword/effie",
  "/meet/zack": "https://calendly.com/afterwordco",
};
// Add trailing slashes
Object.keys(REDIRECT_MAP).forEach((key) => {
  REDIRECT_MAP[key + "/"] = REDIRECT_MAP[key];
});

const MeetPage = ({ location }) => {
  if (!isBrowser) {
    return null;
  }
  let maybeRedirectPath = REDIRECT_MAP[location.pathname.toLowerCase()];
  window.location = maybeRedirectPath || "/";
  return null;
};

export default MeetPage;
